import React, {createContext, useContext, useReducer} from 'react';
import * as Sentry from '@sentry/react';
import {Warning} from '@material-ui/icons';
import {Box} from '@mui/system';
import themeColors from '../assets/theme/colors';

var AuthStateContext = createContext();
var AuthDispatchContext = createContext();

const LOCAL_STORAGE_KEY = "sfsdf234e234h2kjefbwbf";

const ACTION_API_OK = "API_OK";
const ACTION_API_ERROR = "API_ERROR";
const ACTION_LOGIN_SUCCESS = "LOGIN_SUCCESS";
const ACTION_LOGIN_FAILURE = "LOGIN_FAILURE";
const ACTION_REFRESH_SUCCESS = "REFRESH_SUCCESS";
const ACTION_REFRESH_FAILED = "REFRESH_FAILED";
const ACTION_REGISTER_SUCCESS = "REGISTER_SUCCESS";
const ACTION_REGISTER_FAILURE = "REGISTER_FAILURE";
const ACTION_SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
const ACTION_TOKEN_NOT_FOUND = "TOKEN_NOT_FOUND";

const getApiDomain = () => {
  if (/^.+\.dev[\d]+\.tinx\.dk$/.test(window.location.hostname)) {
    return "https://" + window.location.hostname + "/api";
  }
  if (!!process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }
  let baseDomain = "tm-verus.dk";
  if (!!process.env.REACT_APP_BASE_DOMAIN) {
    baseDomain = process.env.REACT_APP_BASE_DOMAIN;
  }
  if (window.location.hostname.endsWith(baseDomain)) {
    return "https://" + window.location.hostname + "/api";
  }
  return 'https://' + window.location.hostname
                      .replace('.' + baseDomain, '')
                      .replace(/\./g, '_') + '.' + baseDomain + '/api';
}

function authReducer(state, action) {
  // console.log('User action', action);
  switch (action.type) {
    case ACTION_API_OK:
      return { ...state, status: 200, msg: 'OK'};
    case ACTION_API_ERROR:
      if (Sentry) Sentry.captureMessage("API Error ("+action.status+"): " + action.msg);
      return { ...state, status: action.status, msg: action.msg};
    case ACTION_LOGIN_SUCCESS:
    case ACTION_REFRESH_SUCCESS:
    case ACTION_REGISTER_SUCCESS:
      return { ...state, status: 200, isAuthenticated: true };
    case ACTION_LOGIN_FAILURE:
    case ACTION_REFRESH_FAILED:
    case ACTION_SIGN_OUT_SUCCESS:
    case ACTION_TOKEN_NOT_FOUND:
    case ACTION_REGISTER_FAILURE:
      return { ...state, status: 200, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function isApiErrorShown() {
  return !!document.getElementById("AuthErrorDetected");
}

function AuthProvider({ children }) {
  var [state, dispatch] = useReducer(authReducer, {
    isAuthenticated: !!localStorage.getItem(LOCAL_STORAGE_KEY),
    status: 200,
  });

  if (!authRefreshTimer) {
    authRefresh();
  }

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {state.status !== 200 && <Box
            id="AuthErrorDetected"
            position={"absolute"}
            bottom={40}
            left={40}
            zIndex={2000}
            color={themeColors.warning.main}
            ><Warning/><br/>{state.msg}</Box>}
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

function useAuthState() {
  var context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
}

function useAuthDispatch() {
  var context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
  return context;
}

function authHeader(returnToken = false) {
  let jwt = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  if (!jwt) {
    return null;
  }
  if (returnToken) return jwt.token;
  return { Authorization: jwt.token_type + " " + jwt.token };
}

const authCallbacks = [];
function callbackOnUserUpdate() {
  let user = authUser();
  //console.debug('Sending user update to callbacks', user);
  authCallbacks.forEach((callback) => {
    callback(user);
  });
}
function registerAuthCallbackOnUserUpdate(callback) {
  authCallbacks.push(callback);
}
function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
function authUser() {
  let jwt = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  let user = { id: null, roles: [], name: "Guest", sip_access_token: null };
  if (jwt && jwt.token) {
    user = parseJwt(jwt.token);
  }
  if (Sentry) Sentry.setUser({...user});
  user.hasRole = (role) => {
    if (Array.isArray(role)) {
      let found = false;
      for (let i = 0; i < role.length; i++) {
        if (user.roles.find((ur) => ur.key === role[i]) !== undefined) {
          found = true;
          break;
        }
      }
      return found;
    }
    return user.roles.find((r) => r.key === role) !== undefined;
  };
  user.isAllowed = (acl) => {
    if (!user.id) return false;
    if (acl === null) return true;
    if (typeof acl === "string" || Array.isArray(acl)) return authUser().hasRole(acl);
    if (typeof acl === "number") return authUser().roles.findIndex(r => r.priority <= acl) !== -1;
    return false;
  }
  return user;
}
function authRoles(hasRole = "") {
  if (hasRole !== "") {
    return authUser().roles.indexOf(hasRole) !== -1;
  }
  return authUser().roles;
}
function authUpdateToken(header) {
  let data = String(header);
  if (data.indexOf("Bearer") === -1) {
    return null;
  }
  let jwt = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  jwt.token = data.substr(7);
  storeJwToken(jwt);
  return true;
}
function storeJwToken(json) {
  json.expires_at = Date.now() + json.expires_in * 1000;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(json));
  callbackOnUserUpdate();
  //console.debug('Storing updated token, expires at', (new Date(json.expires_at)).toLocaleString());
  return json;
}

function authRefresh(forced = false, reload = false) {
  let jwt = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  //console.log('Checking JWT', jwt, authUser());
  if (!jwt) {
    if (window.location.href.indexOf("/admin") !== -1) {
      window.location.href = "/login";
    }
    return false;
  }

  if (!forced && jwt.expires_at - 5000 > Date.now()) {
    setRefreshTimer(jwt.expires_at - Date.now());
    return;
  }

  let user = authUser();
  if (!user) {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    window.location.href = "/login";
    return;
  }
  if (window.apiWorking) {
    //console.debug('API is working, so postpone token refresh for 500ms');
    setTimeout(() => authRefresh(forced, reload), 500);
    return;
  }
  //console.debug('Sending token refresh request', (new Date()).toLocaleString());
  let headers = authHeader();
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  return fetch(getApiDomain() + "/auth/refresh/" + user.id, {
    method: "GET",
    cache: "no-cache",
    mode: "cors",
    headers: {
      ...headers,
      "Accept": "text/json",
      "Accept-Language": window.language
    },
  })
    .then(function (response) {
      if (response.status < 200 || response.status >= 300) {
        window.location.href = "/login";
        Promise.reject("Wrong response code " + response.status);
      }
      return response.json();
    })
    .then((data) => {
      if (!data.token) {
        return;
      }
      data = storeJwToken(data);
      setRefreshTimer(data.expires_in * 1000);
      if (reload) {
        window.location.reload();
      }
      //console.info('Token has been updated, next refresh at', (new Date(data.expires_at)).toLocaleString());
    });
}

let authRefreshTimer = null;
function setRefreshTimer(timeout) {
  timeout -= 2000; // Make sure we refresh before the token expires
  if (authRefreshTimer) {
    clearTimeout(authRefreshTimer);
  }
  //console.debug('Starting timer to refresh token at', (new Date(Date.now()+timeout)).toLocaleString());
  authRefreshTimer = setTimeout(authRefresh, timeout);
}

function authSignOut(dispatch) {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  dispatch({ type: ACTION_SIGN_OUT_SUCCESS });
}

function authLogin(
  dispatch,
  history,
  setIsLoading,
  login,
  password,
  setValidation = null
) {
  setIsLoading(true);

  var formData = new FormData();
  formData.append("email", login);
  formData.append("password", password);
  fetch(getApiDomain() + "/auth/login", {
    method: "POST",
    cache: "no-cache",
    mode: "cors",
    body: formData,
  })
    .then((response) => {
      setIsLoading(false);
      return response.json().then((data) => ({ json: data, meta: response }));
    })
    .then(({ json, meta }) => {
      //console.log('Checking login response: ', meta);
      if (meta.status < 200 || meta.status >= 300) {
        if (setValidation) setValidation(json);
        return;
      }
      json = storeJwToken(json);
      setRefreshTimer((json.expires_in - 10) * 1000);
      dispatch({ type: ACTION_LOGIN_SUCCESS });
      history.push("/");
    })
    .catch(() => {
      if (setValidation)
        setValidation({
          message:
            "The authentication server failed to respond, please try again later.",
        });
      setIsLoading(false);
    });
}

function authRegister(
  dispatch,
  history,
  setIsLoading,
  name,
  login,
  password,
  passwordConfirm,
  setValidation = null
) {
  setIsLoading(true);

  var formData = new FormData();
  formData.append("name", name);
  formData.append("email", login);
  formData.append("password", password);
  formData.append("password_confirmation", passwordConfirm);
  fetch(getApiDomain() + "/auth/register", {
    method: "POST",
    cache: "no-cache",
    mode: "cors",
    body: formData,
  })
    .then((response) => {
      setIsLoading(false);
      return response.json().then((data) => ({ json: data, meta: response }));
    })
    .then(({ json, meta }) => {
      if (meta.status < 200 || meta.status >= 300) {
        if (setValidation) setValidation(json);
      } else {
        history.push("/login");
      }
    })
    .catch(() => {
      setIsLoading(false);
    });
}

function authResend(
  dispatch,
  history,
  setIsLoading,
  email,
  setValidation = null
) {
  setIsLoading(true);

  var formData = new FormData();
  formData.append("email", email);
  formData.append("return_url", window.location.origin + "/verify?id=:id&hash=:hash&expires=:expires&signature=:signature");

  fetch(getApiDomain() + "/auth/email/resend", {
    method: "POST",
    cache: "no-cache",
    mode: "cors",
    headers: {
      ...authHeader(),
      "Accept": "text/json",
      "Accept-Language": window.language
    },
    body: formData,
  })
    .then((response) => {
      setIsLoading(false);
      return response.json().then((data) => ({ json: data, meta: response }));
    })
    .then(({ json, meta }) => {
      //console.log('Checking login response: ', meta);
      if (meta.status < 200 || meta.status >= 300) {
        if (setValidation) setValidation(json);
        return;
      }
      else {
        if (setValidation) setValidation({message: "Sent"});
      }
    })
    .catch(() => {
      if (setValidation)
        setValidation({
          message:
            "The authentication server failed to respond, please try again later.",
        });
      setIsLoading(false);
    });
}

function authVerify(
  dispatch,
  history,
  setIsLoading,
  id,
  hash,
  expires,
  signature,
  token,
  setValidation = null
) {
  setIsLoading(true);

  fetch(getApiDomain() + `/auth/email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`, {
    method: "GET",
    cache: "no-cache",
    mode: "cors",
    headers: {
      ...token,
      "Accept": "text/json",
      "Accept-Language": window.language,
    }
  })
    .then((response) => {
      setIsLoading(false);
      return response.json().then((data) => ({ json: data, meta: response }));
    })
    .then(({ json, meta }) => {
      //console.log('Checking login response: ', meta);
      if (meta.status < 200 || meta.status >= 300) {
        if (setValidation) setValidation(json);
        return;
      }
      else {
        if (setValidation) setValidation({message: "Sent"});
      }
    })
    .catch(() => {
      if (setValidation)
        setValidation({
          message:
            "The authentication server failed to respond, please try again later.",
        });
      setIsLoading(false);
    });
}

function authReset(
  dispatch,
  history,
  setIsLoading,
  token,
  email,
  password,
  confirmPassword,
  setValidation = null
) {
  setIsLoading(true);

  fetch(getApiDomain() + `/auth/password/reset?token=${token}&email=${email}&password=${password}&password_confirmation=${confirmPassword}`, {
    method: "POST",
    cache: "no-cache",
    mode: "cors",
    headers: {
      "Accept": "text/json",
      "Accept-Language": window.language,
    }
  })
    .then((response) => {
      setIsLoading(false);
      return response.json().then((data) => ({ json: data, meta: response }));
    })
    .then(({ json, meta }) => {
      //console.log('Checking login response: ', meta);
      if (meta.status < 200 || meta.status >= 300) {
        if (setValidation) setValidation(json);
        return;
      }
      window.location = "/";
    })
    .catch(() => {
      if (setValidation)
        setValidation({
          message:
            "The authentication server failed to respond, please try again later.",
        });
      setIsLoading(false);
    });
}

function authResetPassword(
  dispatch,
  history,
  setIsLoading,
  email,
  setValidation = null
) {
  setIsLoading(true);

  var formData = new FormData();
  formData.append("email", email);
  formData.append("return_url", window.location.origin + "/reset?token=:token&email=:email");

  fetch(getApiDomain() + "/auth/password/email", {
    method: "POST",
    cache: "no-cache",
    mode: "cors",
    headers: {
      "Accept": "text/json",
      "Accept-Language": window.language
    },
    body: formData
  })
    .then((response) => {
      setIsLoading(false);
      return response.json().then((data) => ({ json: data, meta: response }));
    })
    .then(({ json }) => {
      if (setValidation) setValidation(json);
    })
    .catch(() => {
      if (setValidation)
        setValidation({
          message:
            "The authentication server failed to respond, please try again later.",
        });
      setIsLoading(false);
    });
}

export {
  AuthProvider,
  useAuthState,
  useAuthDispatch,
  authLogin,
  authResend,
  authVerify,
  authReset,
  authResetPassword,
  authSignOut,
  authRegister,
  authHeader,
  authRoles,
  authUser,
  authRefresh,
  registerAuthCallbackOnUserUpdate,
  authUpdateToken,
  getApiDomain,
};
