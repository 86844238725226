import React, {useEffect, useRef, useState} from 'react';

// @material-ui/core components
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  lighten,
  MenuItem,
  SnackbarContent,
  Typography,
} from '@material-ui/core';

// core components
import PhoneSelector from 'views/Dialer/Cards/PhoneSelector';
import Logs from 'views/Dialer/Cards/Logs';
import Subject from './Cards/Subject';
import Comments from 'views/Dialer/Cards/Comments';
import {authUser, useAuthDispatch} from 'contexts/Auth';
// date time
import moment from 'moment';
import 'moment/locale/da';
import {graphQLApi} from 'services/GraphQLApi';
import {Dialog, DialogContent, DialogTitle, Snackbar, TextField} from '@mui/material';
import {useIntl} from 'react-intl';
import SubjectContacts from 'views/Dialer/Cards/SubjectContacts';
import Outcomes from 'views/Dialer/Cards/Outcomes';
import {DataGridPro} from '@mui/x-data-grid-pro';
import themeColors from 'assets/theme/colors';
import SendMail from 'views/Dialer/Dialogs/SendMail';
import HotLeads from 'views/Dialer/Dialogs/HotLeads';
import PhoneDialer from 'views/Dialer/Cards/PhoneDialer';
import {
  Call,
  CallEnd,
  Close,
  ContactPhone,
  Contacts,
  Info,
  ListAltOutlined,
  OpenInNew,
  Search,
} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import componentStyles from 'assets/theme/components/dialer-subject';
import {
  CALL_DIRECTION_INCOMING,
  CALL_STATUS_ACTIVE,
  CALL_STATUS_IDLE,
  CALL_STATUS_STARTING,
  SIP_STATUS_DISCONNECTED,
  SipProvider,
} from '@tinxdk/react-sip';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import Pill from 'components/Pill/Pill';
import {useHistory} from 'react-router-dom';
import SendSms from 'views/Dialer/Dialogs/SendSms';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ContactsFilter from '../../components/ContactsFilter';
import da from 'date-fns/locale/da';
import {useCalls} from "../../contexts/Calls";

const useStyles = makeStyles(componentStyles);

let callId = null;

const Dialer = (props, SipProvider) => {
  registerLocale('da', da);
  setDefaultLocale('da');
  const classes = useStyles();
  const intl = useIntl();
  const dataGridRef = useRef({});
  const searchTableRef = useRef();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const {updateCallStats} = useCalls();
  ////// overlay for non-auth users - jsx conditional backdrop//////
  const [isLoadingAssignment, setIsLoadingAssignment] = useState(false);
  const [isLoadingSubjects, setIsLoadingSubjects] = useState(false);

  const assignmentId = props.match.params && props.match.params.assignmentID;
  const client = new graphQLApi(useAuthDispatch(), history);

  const [assignment, setAssignment] = useState({name: '', notes: '', questions: []});
  const [outcomes, setOutcomes] = useState([]);
  const [activeOutcome, setActiveOutcome] = useState(null);
  const [subjectIndex, setSubjectIndex] = useState(0);
  const [subjects, setSubjects] = useState([]);
  const [subjectFields, setSubjectFields] = useState([]);
  const [showAssignmentHasBeenClosed, setShowAssignmentHasBeenClosed] = useState(false);
  const [showNoSubjects, setShowNoSubjects] = useState(false);
  const [showSubjectList, setShowSubjectList] = useState(false);
  const [loadSubjectsForEditing, setLoadSubjectsForEditing] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [settings, setSettings] = useState({});
  const [columns, setColumns] = useState([
    {field: 'id', headerName: 'HID', width: 80},
    {field: 'subject_id', headerName: 'SID', width: 80},
    {field: 'name', headerName: 'Navn', width: 200},
    {field: 'address_1', headerName: 'Addresse', width: 250},
    {field: 'zip', headerName: 'Postnr'},
    {field: 'city', headerName: 'By', width: 150},
    {field: 'next_contact_at', headerName: 'Næste kontakt', width: 150},
    {field: 'business_type', headerName: 'Branche', width: 300},
    {field: 'employees', headerName: 'Ansatte'},
    {field: 'calls_made', headerName: 'Opkald'},
    {field: 'subject_contacts_count', headerName: 'Kontakter'},
  ]);
  const countries = {
    DK: {primary: intl.formatMessage({id: 'country.denmark', defaultMessage: 'Denmark'}), secondary: '+45'},

    AT: {primary: intl.formatMessage({id: 'country.austria', defaultMessage: 'Austria'}), secondary: '+43'},
    BE: {primary: intl.formatMessage({id: 'country.belgium', defaultMessage: 'Belgium'}), secondary: '+32'},
    BG: {primary: intl.formatMessage({id: 'country.bulgaria', defaultMessage: 'Bulgaria'}), secondary: '+359'},
    HR: {primary: intl.formatMessage({id: 'country.croatia', defaultMessage: 'Croatia'}), secondary: '+385'},
    CY: {primary: intl.formatMessage({id: 'country.cyprus', defaultMessage: 'Cyprus'}), secondary: '+357'},
    CZ: {
      primary: intl.formatMessage({id: 'country.czech_republic', defaultMessage: 'Czech Republic'}),
      secondary: '+420',
    },
    EE: {primary: intl.formatMessage({id: 'country.estonia', defaultMessage: 'Estonia'}), secondary: '+372'},
    FI: {primary: intl.formatMessage({id: 'country.finland', defaultMessage: 'Finland'}), secondary: '+358'},
    FR: {primary: intl.formatMessage({id: 'country.france', defaultMessage: 'France'}), secondary: '+33'},
    DE: {primary: intl.formatMessage({id: 'country.germany', defaultMessage: 'Germany'}), secondary: '+49'},
    GR: {primary: intl.formatMessage({id: 'country.greece', defaultMessage: 'Greece'}), secondary: '+30'},
    HU: {primary: intl.formatMessage({id: 'country.hungary', defaultMessage: 'Hungary'}), secondary: '+36'},
    IS: {primary: intl.formatMessage({id: 'country.iceland', defaultMessage: 'Iceland'}), secondary: '+354'},
    IE: {primary: intl.formatMessage({id: 'country.ireland', defaultMessage: 'Ireland'}), secondary: '+353'},
    IT: {primary: intl.formatMessage({id: 'country.italy', defaultMessage: 'Italy'}), secondary: '+39'},
    LV: {primary: intl.formatMessage({id: 'country.latvia', defaultMessage: 'Latvia'}), secondary: '+371'},
    LI: {
      primary: intl.formatMessage({id: 'country.liechtenstein', defaultMessage: 'Liechtenstein'}),
      secondary: '+423',
    },
    LT: {primary: intl.formatMessage({id: 'country.lithuania', defaultMessage: 'Lithuania'}), secondary: '+370'},
    LU: {primary: intl.formatMessage({id: 'country.luxembourg', defaultMessage: 'Luxembourg'}), secondary: '+352'},
    MT: {primary: intl.formatMessage({id: 'country.malta', defaultMessage: 'Malta'}), secondary: '+356'},
    NL: {primary: intl.formatMessage({id: 'country.netherlands', defaultMessage: 'Netherlands'}), secondary: '+31'},
    NO: {primary: intl.formatMessage({id: 'country.norway', defaultMessage: 'Norway'}), secondary: '+47'},
    PL: {primary: intl.formatMessage({id: 'country.poland', defaultMessage: 'Poland'}), secondary: '+48'},
    PT: {primary: intl.formatMessage({id: 'country.portugal', defaultMessage: 'Portugal'}), secondary: '+351'},
    RO: {primary: intl.formatMessage({id: 'country.romania', defaultMessage: 'Romania'}), secondary: '+40'},
    SK: {primary: intl.formatMessage({id: 'country.slovakia', defaultMessage: 'Slovakia'}), secondary: '+421'},
    SI: {primary: intl.formatMessage({id: 'country.slovenia', defaultMessage: 'Slovenia'}), secondary: '+386'},
    ES: {primary: intl.formatMessage({id: 'country.spain', defaultMessage: 'Spain'}), secondary: '+34'},
    SE: {primary: intl.formatMessage({id: 'country.sweden', defaultMessage: 'Sweden'}), secondary: '+46'},

    GL: {primary: intl.formatMessage({id: 'country.greenland', defaultMessage: 'Greenland'}), secondary: '+299'},
    FO: {
      primary: intl.formatMessage({id: 'country.faroe-islands', defaultMessage: 'Faroe Islands'}),
      secondary: '+298',
    },
    AD: {primary: intl.formatMessage({id: 'country.andorra', defaultMessage: 'Andorra'}), secondary: '+376'},
    MC: {primary: intl.formatMessage({id: 'country.monaco', defaultMessage: 'Monaco'}), secondary: '+377'},
    SM: {primary: intl.formatMessage({id: 'country.san-marino', defaultMessage: 'San Marino'}), secondary: '+378'},
    GI: {primary: intl.formatMessage({id: 'country.gibraltar', defaultMessage: 'Gibraltar'}), secondary: '+350'},
    CH: {primary: intl.formatMessage({id: 'country.switzerland', defaultMessage: 'Switzerland'}), secondary: '+41'},
    GB: {
      primary: intl.formatMessage({id: 'country.united-kingdom', defaultMessage: 'United Kingdom'}),
      secondary: '+44',
    },
    UA: {primary: intl.formatMessage({id: 'country.ukraine', defaultMessage: 'Ukraine'}), secondary: '+380'},

    US: {primary: intl.formatMessage({id: 'country.usa', defaultMessage: 'USA'}), secondary: '+1'},
    CA: {primary: intl.formatMessage({id: 'country.canada', defaultMessage: 'Canada'}), secondary: '+1'},

    AU: {primary: intl.formatMessage({id: 'country.australia', defaultMessage: 'Australia'}), secondary: '+61'},
    NZ: {primary: intl.formatMessage({id: 'country.new-zealand', defaultMessage: 'New Zealand'}), secondary: '+64'},
  };
  const [selectedCountry, setSelectedCountry] = useState('DK');
  const [selectedPhone, setSelectedPhone] = useState(null);
  const [call, setCall] = useState(null);
  const [returnToSubject, setReturnToSubject] = useState(null);

  useEffect(() => {
    // console.log('CALL CHANGE', SipProvider, callId, call);
    let assignmentSubject = getAssignmentSubject();
    if (SipProvider?.call?.status === CALL_STATUS_STARTING && !callId) {
      callId = true;
      if (SipProvider.call.direction === CALL_DIRECTION_INCOMING) {
        let s = SipProvider.call.counterpart?.split(' <')[0]?.replaceAll('"', '');
        console.log('Incoming call from', SipProvider.call.counterpart);
        setCall({
          status: 'ringing',
          direction: 'incoming',
          started: moment().format('YYYY-MM-DD HH:mm:ss'),
          counterpart: s,
        });
        if (assignmentSubject?.id) {
          saveSubject(assignmentSubject).then(() => {
            setSearch({...search, telephone: s});
            setSearchFilter('telephone:"' + s + '"');
            setShowSearch(true);
          });
        }
      }
      else {
        setCall({status: 'dialing', direction: 'outgoing', started: moment()});
        client.mutate('{assignmentSubjectDial(id:' + assignmentSubject.id + ')}').then(r => {
          if (r && r.hasOwnProperty('assignmentSubjectDial')) {
            setCall({
              started: moment().format('YYYY-MM-DD HH:mm:ss'),
              id: SipProvider.call.counterpart,
              status: 'dialing',
              counterpart: selectedPhone,
              ...call,
            });
            console.log('STARTING', r.assignmentSubjectDial);
            callId = r.assignmentSubjectDial;
          }
        });
      }
    } else if (SipProvider?.call?.status === CALL_STATUS_ACTIVE && callId && call !== null) {
      setCall({...call, status: 'accepted'});
      console.log('ACCEPTED', callId);
    }
    else if (SipProvider?.call?.status === CALL_STATUS_IDLE && call !== null) {
      // TODO How should we register an incoming call duration, what subject to do it on????
      /**
       * Register a call duration on the assignment that was dialed
       */
      console.log('IDLE', callId, call);
      if (call && callId !== true && callId > 0) {
        client.mutate('($call_data:String!)' +
            '{' +
            ' assignmentSubjectHangup(id:' + callId + ' call_data:$call_data)' +
            '   {id user{name} duration ended_at}' +
            '}', {
          call_data: JSON.stringify({
            ...call,
            ended: moment().format('YYYY-MM-DD HH:mm:ss'),
          }),
        }).then(r => {
          updateCallStats();
          console.log('ENDED', r);
          if (r && r.hasOwnProperty('assignmentSubjectHangup')) {
            let assignmentSubject = getAssignmentSubject();
            updateSubject({
              calls_made: assignmentSubject.calls_made++,
              calls: [
                r.assignmentSubjectHangup,
                ...assignmentSubject.calls,
              ],
            });
          }
        });
      }
      else if (call?.started) {
        let match = false;
        if (call.direction === 'incoming' && assignmentSubject?.subject && !!call.counterpart) {
          let keys = Object.keys(assignmentSubject.subject).filter(k => k.includes('phone'));
          for (let c = 0; c < keys.length; c++) {
            if (assignmentSubject.subject[keys[c]]?.replace(' ', '').includes(call.counterpart)) {
              match = true;
            }
          }
          for (let i = 0; i < assignmentSubject.subject_contacts?.length; i++) {
            keys = Object.keys(assignmentSubject.subject_contacts[i]).filter(k => k.includes('phone'));
            for (let c = 0; c < keys.length; c++) {
              if (assignmentSubject.subject_contacts[i][keys[c]]?.replace(' ', '').includes(call.counterpart)) {
                match = true;
              }
            }
          }
        }
        client.mutate(
            '($call_data:String!) {assignmentCallCreate(' +
            ' user_id:' + authUser().id +
            ' assignment_id:' + (match ? assignmentId : 'null') +
            ' assignment_subject_id:' + (match ? assignmentSubject.id : 'null') +
            ' ended_at:"' + moment().format('YYYY-MM-DD HH:mm:ss') + '"' +
            ' created_at:"' + moment(call.started).format('YYYY-MM-DD HH:mm:ss') + '"' +
            ' duration:' + moment().diff(call.started, 'seconds') +
            ' call_data:$call_data' +
            '){id user{name} duration ended_at}}', {
              call_data: JSON.stringify({
                ...call,
                status: call.status === 'ringing' ? 'no-answer' : call.status,
                ended: moment().format('YYYY-MM-DD HH:mm:ss'),
              }),
            }).then(r => {
          updateCallStats();
          if (r && r.hasOwnProperty('assignmentCallCreate') && match) {
            let assignmentSubject = getAssignmentSubject();
            updateSubject({
              calls_made: assignmentSubject.calls_made++,
              calls: [
                r.assignmentCallCreate,
                ...assignmentSubject.calls,
              ],
            });
          }
        });
      }
      setCall(null);
      callId = null;
    }
  }, [SipProvider?.call]);

  const handleCalling = (call) => {
    if (call) {
      const aSubject = getAssignmentSubject();
      if (aSubject && SipProvider && SipProvider.sip.status) {
        saveSubject(aSubject).then(_ => {
          if (SipProvider?.call?.direction === CALL_DIRECTION_INCOMING) {
            SipProvider.answerCall();
          }
          else {
            let cCode = countries[selectedCountry]?.secondary;
            if (cCode === '+45') cCode = '';
            SipProvider.startCall(cCode + selectedPhone);
          }
        });
      }
    }
    if (!call) {
      if (SipProvider?.call?.status !== CALL_STATUS_IDLE) {
        SipProvider.stopCall();
      }
    }
  };

  const calendarWindow = useRef();
  const calendarWindowAssignment = (allowOpening = true) => {
    if (calendarWindow.current?.location?.href) {
      // console.log(calendarWindow.current.location.href.endsWith('/dialer/' + assignmentId + '/calendar'), calendarWindow.current.location.href, '/dialer/' + assignmentId + '/calendar', calendarWindow.current);
      if (!calendarWindow.current.location.href.endsWith('/dialer/' + assignmentId + '/calendar')) {
        calendarWindow.current.location.href = '/dialer/' + assignmentId + '/calendar';
      }
      else {
        calendarWindow.current.refreshEvents();
      }
    }
    else if (allowOpening)
      calendarWindow.current = window.open('/dialer/' + assignmentId + '/calendar', 'assignment_calendar');
  };

  const subjectContactFields = 'id title name email phone mobile department_id';
  const hotLeadSubjectFields = 'id next_contact_at comment outcome{id}' +
      '  assignment{id name}' +
      '  subject{id name address_1 address_2 zip city state country_code main_phone direct_phone mobile_phone email}' +
      '  subject_contact{' + subjectContactFields + '}';
  const assignmentSubjectFields = 'id assignment_id handled_status comment ext_comment calls_made last_edited_by_phoner_at last_edited_by_manager_at next_contact_at meeting_established_at subject_contact_id\n' +
      '        outcome{id name}\n' +
      '        phoner{id name}\n' +
      '        calls{id user{name} duration ended_at}\n' +
      '        status_history{id status{name} user{name} created_at}\n' +
      '        subject_field_data(assignment_id:'+assignmentId+'){id data subject_field_id}\n' +
      '        answers(assignment_id:'+assignmentId+'){id text assignment_question_id}\n' +
      '        subject{id name address_1 address_2 zip city state country_code meeting_address_1 meeting_address_2 meeting_zip meeting_city main_phone direct_phone mobile_phone email website registration_number business_type employees\n' +
      '          subject_contacts{' + subjectContactFields + '}' +
      '        }';

  const updateSubject = (subjectData) => {
    if (!subjects[subjectIndex]) {
      return;
    }
    const newSubs = [...subjects];
    newSubs[subjectIndex] = {...subjects[subjectIndex], ...subjectData};
    setSubjects(newSubs);
  };
  const [hotleadsPostponed, setHotleadsPostponed] = useState(false);

  useEffect(() => {
    setSubjectFields([]);
    setOutcomes([]);
    setActiveOutcome(null);
    if (assignmentId) {
      setIsLoadingAssignment(true);
      let filter = '';
      if (props.location.hash === '#edit_subjects') {
        let ids = localStorage.getItem('assignment_subject_ids');
        filter = ' subject_ids:[' + ids + ']';
      }

      calendarWindowAssignment(false);

      client.query('{\n' +
          'users(filter:{id:' + authUser().id + '}){data{sip_credential{number login auth} settings}}\n' +
          'settings{data{key data}}\n' +
          'departments{data{id name}}\n' +
          'hot_leads: assignmentSubjects(filter:{is_hot_lead:true}){data{' + hotLeadSubjectFields + '}}' +
          'assignmentSubjectFields(filter:{assignment_id:' + assignmentId + '},sorting:"name",direction:"asc"){data{id name}}' +
          'assignmentOutcomes(counts:["assignment_subjects"],filter:{assignment_id:' + assignmentId + filter + '}){data{' +
          '  id assignment_subjects_count outcome{id name is_closed is_date_required is_target}' +
          '}}\n' +
          'assignments(filter:{id:' + assignmentId + '}) {\n' +
          '    data{\n' +
          '      id name notes is_active allow_sending_meeting_invites allow_sending_direct_mail allow_sending_alt_mail_1 allow_sending_alt_mail_2 allow_sending_sms add_phoner_comment_details\n' +
          '      email_settings{subject_sender_name phoner_information subject_sender_email confirmation_template_id direct_template_id alternative_1_template_id alternative_2_template_id}\n' +
          '      subject_fields{id name category is_highlighted}\n' +
          '      highlighted_fields {id is_highlighted}\n' +
          '      questions{id text is_answer_required is_multiple_choice sorting answers{text next_assignment_question{id}}}\n' +
          '    }\n' +
          '  }\n' +
          '}\n').then(result => {
        setIsLoadingAssignment(false);
        if (!result) {
          return;
        }
        let isPostponed = false;
        let postponed = null;
        if (result.hasOwnProperty('settings') && result.hasOwnProperty('users')) {
          let set = {};
          result.settings.data.forEach(s => {
            set[s.key] = s.data;
          });
          if (result.users.data[0] && result.users.data[0].hasOwnProperty('sip_credential') && result.users.data[0].sip_credential) {
            set.sip_number = result.users.data[0].sip_credential.number;
            set.sip_username = result.users.data[0].sip_credential.login;
            set.sip_password = result.users.data[0].sip_credential.auth;
          }
          setSettings({...settings, ...set});
          if (result.users.data[0].settings) {
            postponed = JSON.parse(result.users.data[0].settings)?.hotlead_postponed ?? null;
            isPostponed = moment(postponed).isSame(moment(), 'day');
            setHotleadsPostponed(isPostponed);
          }
        }
        if (result.hasOwnProperty('departments')) {
          setDepartments(result.departments.data);
        }
        if (result.hasOwnProperty('assignmentSubjectFields')) {
          setColumns([...columns, ...result.assignmentSubjectFields.data.map(f => ({headerName: f.name, field: 'subject_field_data_' + f.id}))]);
        }
        if (props.location.hash !== '#edit_subjects' && !urlParams.has('outcomeId') && result.hasOwnProperty('hot_leads')) {
          let leads = result.hot_leads.data.sort((a, b) => {
            a = moment(a.next_contact_at);
            b = moment(b.next_contact_at);
            if (a.isBefore(b)) {
              return -1;
            }
            if (a.isAfter(b)) {
              return 1;
            }
            return 0;
          });
          let hasLeads = leads.length > 0;
          console.log('leads', leads);
          console.log('isPostponed', isPostponed, postponed);
          if (isPostponed !== false && typeof postponed === 'string') {
            console.log('filtering leads based on postPoned timestamp');
            let pp = moment(postponed);
            hasLeads = leads.filter(s => {
              let nca = moment(s.next_contact_at);
              return pp.isBefore(nca);
            }).length > 0;
          }
          console.log('hasLeads', hasLeads);
          if (hasLeads) {
            setHotLeads(leads);
          }
          setHotLeadSelected(0);
        }
        if (result.hasOwnProperty('assignmentOutcomes')) {
          const assignmentOutcomes = result.assignmentOutcomes.data;
          setOutcomes(assignmentOutcomes.map(o => ({
            ...o.outcome,
            count: o.assignment_subjects_count,
          })));
          if (props.location.hash === '#edit_subjects') {
            setLoadSubjectsForEditing(true);
          }
          else if (urlParams.has('outcomeId')) {
            setActiveOutcome(parseInt(urlParams.get('outcomeId')));
          }
          else if (assignmentOutcomes[0]) {
            setActiveOutcome(assignmentOutcomes[0].outcome.id);
          }
        }
        if (result.hasOwnProperty('assignments') && result.assignments.data?.length) {
          const assignmentData = result.assignments.data[0];
          if (!assignmentData.is_active) {
            window.location.href = '/dialer';
            return;
          }
          setAssignment(assignmentData);

          if (assignmentData.subject_fields.length) {
            const highlightedFields = assignmentData.highlighted_fields.map(f => f.id);
            const subFields = [{name: 'Extra felter', fields: []}];
            assignmentData.subject_fields.forEach(f => {
              let categoryIndex = 0;
              // if (f.category) {
              //   categoryIndex = subFields.findIndex(c => c.name === f.category);
              //   if (categoryIndex < 0) {
              //     categoryIndex = subFields.push({
              //       name: f.category,
              //       fields: [],
              //     }) - 1;
              //   }
              // }
              highlightedFields.includes(f.id) ? f.field_highlighted = true :  f.field_highlighted = false;
              subFields[categoryIndex].fields.push(f);
            });
            setSubjectFields(subFields);
          }
        }
      });
    }
  }, [assignmentId]);

  const [sortModel, setSortModel] = useState([
    {
      field: 'next_contact_at',
      sort: 'asc',
    },
  ]);
  const [sortedIndex, setSortedIndex] = useState(null);
  const [sortedIds, setSortedIds] = useState([]);
  const getNextSubjectIndexBasedOnSorting = (newSubjects, newOutcomeId) => {
    const newSubjectIds = newSubjects.map(s => s.id);
    let sorted = [];
    // console.log(dataGridRef.current?.getSortedRowIds);
    if (dataGridRef.current?.getSortedRowIds) {
      sorted = dataGridRef.current.getSortedRowIds();
    }
    else if (sortedIds.length) {
      sorted = sortedIds.filter(s => newSubjectIds.includes(s));
    }
    else {
      sorted = newSubjectIds;
    }
    setSortedIds(sorted);
    let nextId = null;
    let newIndex = 0;
    let nextSubjectIndex = subjectIndex;
    // console.log(activeOutcome, newOutcomeId, sortedIndex, sorted[sortedIndex], sorted, nextSubjectIndex, newSubjects.map(s => s.id));
    if (sorted.length) {
      newIndex = sortedIndex;
      if (newIndex === null || newIndex === undefined) {
        newIndex = sorted.findIndex(s => s === subjects[subjectIndex]?.id);
        if (newIndex < 0) newIndex = 0;
        else newIndex++;
      }
      else if (sorted.length <= newIndex + 1) {
        newIndex = 0;
      }
      else {
        newIndex++;
      }
      if (activeOutcome === newOutcomeId) {
        setSortedIndex(newIndex);
      }
      // console.log(activeOutcome, newOutcomeId, newIndex, subjectIndex);
      nextId = sorted[newIndex];
      // console.log(sortedIndex, newIndex, nextId);
      if (!nextId) {
        nextId = sorted[0];
      }
      nextSubjectIndex = newSubjects.findIndex(s => s.id === nextId);
      // console.log(nextId, nextSubjectIndex);
      if (nextSubjectIndex === -1) {
        // console.log(nextSubjectIndex);
        nextSubjectIndex = 0;
      }
    }
    else if (nextSubjectIndex === null || ++nextSubjectIndex >= newSubjects.length) {
      nextSubjectIndex = 0;
      // console.log(nextSubjectIndex);
    }
    // console.log(nextSubjectIndex);
    return nextSubjectIndex;
  };

  const updateSubjectsAndRelated = (subjs, newOutcomeId, hotLeadId) => {
    setSubjects(subjs.map(s => ({
      ...s,
      assignment_id: assignmentId,
      subject_contacts_count: s.subject?.subject_contacts?.length || 0,
      subject: {
        ...s.subject,
        subject_contacts: s.subject.subject_contacts?.map(c => ({
            ...c,
            department_id: c.department_id ? c.department_id : '',
          })) || [],
      },
    })));
    let index = null;
    if (loadSubjectsForEditing) {
      index = getNextSubjectIndexBasedOnSorting(subjs, 0);
      setSubjectIndex(index);
      setSelectedPhone(subjs[index]?.subject?.main_phone?.replaceAll(' ', ''));
    }
    else {
      if (subjs.length) {
        if (hotLeadId) {
          // console.log('Goto hotLead ID', hotLeadId);
          index = subjs.findIndex(s => s.id === hotLeadId);
          // If the hotlead subject is not in the current list, because the assignment is set to not show past closed subjects, then what?
          // Do we extra load it and inject it in??
          if (index > -1) {
            // console.log('Goto hotLead index', index);
            setSubjectIndex(index);
          }
          // else
          //   console.log('No index found for hotLead');
        }
        else {
          index = getNextSubjectIndexBasedOnSorting(subjs, newOutcomeId);
          setSubjectIndex(index);
        }
        setSelectedPhone(subjs[index]?.subject?.main_phone?.replaceAll(' ', ''));
      }
      else {
        setShowNoSubjects(true);
        setSubjectIndex(null);
        setSelectedPhone(null);
      }
    }
  };

  useEffect(() => {
    setShowNoSubjects(false);
    setSubjectIndex(0);
    setSelectedPhone(null);
    setSubjects([]);
    const urlParams = new URLSearchParams(window.location.search);
    let hotLeadId = urlParams.get('hotLeadId');
    const outcomeId = urlParams.get('outcomeId');
    // console.log('useEffect::activeOutcome', activeOutcome, loadSubjectsForEditing, outcomeId, hotLeadId);
    if (outcomeId && parseInt(activeOutcome) !== parseInt(outcomeId)) return;
    if (!!assignmentId && (!outcomeId || parseInt(activeOutcome) === parseInt(outcomeId))) {
      history.replace('/dialer/' + assignmentId);
    }
    let filter = '';
    if (activeOutcome) {
      filter = 'phoner_id:' + authUser().id + ' outcome_id:' + activeOutcome;
    }
    if (loadSubjectsForEditing) {
      let ids = localStorage.getItem('assignment_subject_ids');
      filter = 'ids:[' + ids + ']';
    }
    if (filter && assignmentId) {
      setIsLoadingSubjects(true);
      client.query('{\n' +
          'assignments(filter:{id:' + assignmentId + '}) {\n' +
          '    data{\n' +
          '      subjects(' + filter + ' sorting:"next_contact_at" direction:"asc"){\n' +
          '        ' + assignmentSubjectFields +
          '      }\n' +
          '    }\n' +
          '  }\n' +
          '}\n').then(result => {
        setIsLoadingSubjects(false);
        if (!result || !result.assignments?.data?.length) {
          return;
        }
        const assignmentData = result.assignments.data[0];
        if (assignmentData.hasOwnProperty('subjects') && assignmentData.subjects) {
          // if (assignmentData.subjects.length === 0) {
          //   handleOutcomeChange(1, null);
          // }
          // else {
          if (!hotLeadId) {
            assignmentData.subjects.find(subject => {
              if (subject.next_contact_at && moment(subject.next_contact_at).diff(moment(), 'minutes') < 5) {
                hotLeadId = subject.id;
                return true;
              }
              return false;
            });
          }
          updateSubjectsAndRelated(assignmentData.subjects, outcomeId, hotLeadId);
          // }
        }
      });
    }
  }, [activeOutcome, loadSubjectsForEditing]);

  const getAssignmentSubject = (type = null) => {
    let assignmentSubject = {
      id: null,
      comment: '',
      ext_comment: '',
      calls_made: 0,
      next_contact_at: '',
      next_contact_duration: '',
      outcome: null,
      answers: [],
      calls: [],
      status_history: [],
      subject: null,
      subject_contact_id: null,
    };
    if (subjectIndex !== null && subjects[subjectIndex]) {
      assignmentSubject = {...assignmentSubject, ...subjects[subjectIndex]};
    }
    switch (type) {
      case 'call_log':
        return assignmentSubject.calls;
      case 'status_log':
        return assignmentSubject.status_history;
      case 'answers':
        return assignmentSubject.answers;
      case 'comment':
        return assignmentSubject.comment;
      case 'contact':
        if (!assignmentSubject.subject_contact_id || !assignmentSubject.subject.subject_contacts) {
          return null;
        }
        return assignmentSubject.subject.subject_contacts.find(c => c.id === assignmentSubject.subject_contact_id);
      case 'subject':
        return assignmentSubject.subject;
      case 'subject_contacts':
        return assignmentSubject.subject ? assignmentSubject.subject.subject_contacts : [];
      default:
        return assignmentSubject;
    }
  };

  const [isCreatingNewContact, setIsCreatingNewContact] = useState(false);
  const updateSubjectContact = (property, value) => {
    const newSubject = getAssignmentSubject();
    let promise = null;
    if (property === 'create') {
      setIsCreatingNewContact(true);
      promise = client.mutate('{' +
          'subjectContactCreate(' +
          ' subject_id:' + newSubject.subject.id +
          ' name:"' +
          intl.formatMessage({
            id: 'dialer.subject_contact.create_new.name',
            defaultMessage: 'New contact',
          }) + '"' +
          ') {' + subjectContactFields + '}' +
          '}').then(r => {
        if (r && r.hasOwnProperty('subjectContactCreate')) {
          const subject = getAssignmentSubject();
          subject.subject_contact_id = r.subjectContactCreate.id;
          if (!Array.isArray(subject.subject.subject_contacts)) {
            subject.subject.subject_contacts = [];
          }
          subject.subject.subject_contacts.push({
            id: r.subjectContactCreate.id,
            name: r.subjectContactCreate.name,
            email: '',
            title: '',
            phone: '',
            mobile: '',
            department_id: '',
          });
          updateSubject(subject);
        }
        setIsCreatingNewContact(false);
      });
    }
    else if (property === 'delete') {
      client.mutate('{subjectContactDelete (id:' + value?.id + ')}').then(_r => {
            const updatedSubjects = subjects;
            updatedSubjects[subjectIndex].subject.subject_contacts = updatedSubjects[subjectIndex].subject.subject_contacts.filter(c => c.id !== value.id);
            setSubjects(updatedSubjects);
          });
    }
    else {
      let index = newSubject.subject.subject_contacts.findIndex(
          c => c.id === subjects[subjectIndex].subject_contact_id);
      newSubject.subject.subject_contacts[index][property] = value;
    }
    updateSubject(newSubject);
    return promise;
  };

  const onCopySubjectContact = (contact) => {
    const updatedSubjects = [...subjects];
    const newContact = {
      department_id: contact.department?.id,
      email:contact.email,
      id: contact.id,
      mobile: contact.mobile,
      name: contact.name,
      phone: contact.phone,
      title: contact.title
    }
    updatedSubjects[subjectIndex].subject.subject_contacts.push(newContact);
    updatedSubjects[subjectIndex].subject_contact_id = contact.id;
    setSubjects(updatedSubjects);
  }
  const saveSubject = (subject) => {
    const vars = {
      'id': 'ID!',
      'next_contact_at': 'String',
      'comment': 'String',
      'new_comment': 'String',
      'ext_comment': 'String',
      'subject_contact_id': 'ID',
      'subject': 'SubjectInput',
      'answers': '[AssignmentQuestionAnswerInput]',
      'subject_field_data': '[SubjectFieldDataInput]',
    };
    const data = {...subject};
    if (data.subject?.subject_contacts?.length) {
      data.subject.subject_contacts = data.subject.subject_contacts.map(c => ({
        id: c.id,
        name: c.name,
        title: c.title,
        email: c.email,
        phone: c.phone,
        mobile: c.mobile,
        department_id: c.department_id,
      }));
    }
    return client.mutation('assignmentSubject', vars, data, assignmentSubjectFields).then(r => {
      if (r && r.hasOwnProperty('response')) {
        let idx = subjects.findIndex(s => s.id === r.response.id);
        if (idx) {
          const newSubs = [...subjects];
          if (subject.subject_contact_id && !r.response.subject_contact_id) {
            let cIdx = subject.subject.subject_contacts.findIndex(c => c.id === subject.subject_contact_id);
            if (cIdx > -1) {
              r.response.subject_contact_id = subject.subject_contact_id;
              r.response.subject.subject_contacts.push(
                  subject.subject.subject_contacts[cIdx],
              );
            }
          }
          newSubs[idx] = r.response;
          setSubjects(newSubs);
        }
      }
    });
  };

  const [alertMessage, setAlertMessage] = useState('');
  const handleOutcomeChange = (outcomeId, statusId = null) => {
    const currentSubject = subjects[subjectIndex];
    const variables = {'assignment_id': 'ID!'};
    const data = {assignment_id: assignmentId};
    history.replace('/dialer/' + assignmentId);
    if (currentSubject) {
      variables.id = 'ID';
      variables.next_contact_at = 'String';
      variables.comment = 'String';
      variables.new_comment = 'String';
      variables.ext_comment = 'String';
      variables.subject_contact_id = 'ID';
      variables.subject = 'SubjectInput';
      variables.answers = '[AssignmentQuestionAnswerInput]';
      variables.subject_field_data = '[SubjectFieldDataInput]';
      data.id = currentSubject.id;
      data.next_contact_at = currentSubject.next_contact_at;
      data.comment = currentSubject.comment;
      data.new_comment = currentSubject.new_comment;
      data.ext_comment = currentSubject.ext_comment;
      data.subject_contact_id = currentSubject.subject_contact_id;
      data.subject = currentSubject.subject;
      data.answers = currentSubject.answers;
      data.subject_field_data = currentSubject.subject_field_data;
      if (outcomeId && (!currentSubject.outcome || outcomeId !== currentSubject.outcome.id)) {
        let outcome = outcomes.find(o => o.id === outcomeId);
        if (outcome && outcome.is_date_required &&
            !currentSubject.next_contact_at) {
          setAlertMessage(intl.formatMessage(
              {
                id: 'dialer.alert.date_is_required',
                defaultMessage: 'Next contact date has to be set for outcome "{outcome}"',
              }, {
                outcome: outcome.name,
              }));
          return;
        }
        variables.outcome_id = 'ID';
        data.outcome_id = outcomeId;
      }
      if (statusId) {
        variables.status_id = 'ID';
        data.status_id = statusId;
      }
    }
    if (loadSubjectsForEditing) {
      variables.subject_ids = '[ID]';
      data.subject_ids = localStorage.getItem('assignment_subject_ids').split(',');
    }
    setIsLoadingSubjects(true);
    client.mutationCustom('assignmentSubjectHandled', variables, data,
        'status hot_leads{' + hotLeadSubjectFields + '} subjects{' + assignmentSubjectFields + '} updated_subject{' + assignmentSubjectFields + '} follow_up_id ' +
        'outcomes{id assignment_subjects_count outcome{id name is_closed is_date_required is_target} }').then(r => {
      setIsLoadingSubjects(false);
      calendarWindowAssignment(false);
      if (r && r.hasOwnProperty('response')) {
        const response = r.response;
        if (response.hasOwnProperty('outcomes') && response.outcomes.length) {
          setOutcomes(response.outcomes.map(o => ({
            ...o.outcome,
            count: o.assignment_subjects_count,
          })));
        }
        if (response.hasOwnProperty('hot_leads') && response.hot_leads.length && !hotleadsPostponed) {
          setHotLeads(response.hot_leads.sort((a, b) => {
            a = moment(a.next_contact_at);
            b = moment(b.next_contact_at);
            if (a.isBefore(b)) {
              return -1;
            }
            if (a.isAfter(b)) {
              return 1;
            }
            return 0;
          }));
          setHotLeadSelected(0);
        }
        if (!loadSubjectsForEditing) {
          if (response.status === 'max-closed-reached') {
            setShowAssignmentHasBeenClosed(true);
            return;
          }
          let newSubjects = [...subjects];
          if (currentSubject) {
            if (parseInt(activeOutcome) !== parseInt(outcomeId)) {
              newSubjects = newSubjects.filter(s => s.id !== currentSubject.id);
            }
            else if (response.hasOwnProperty('updated_subject')) {
              let idx = newSubjects.findIndex(s => s.id === currentSubject.id);
              newSubjects[idx] = response.updated_subject;
            }
          }
          if (response.hasOwnProperty('subjects') && response.subjects && response.subjects.length) {
            let existingIds = newSubjects.map(s => s.id);
            newSubjects = newSubjects.concat(response.subjects.filter(s => s.outcome.id === activeOutcome && !existingIds.includes(s.id)));
          }
          updateSubjectsAndRelated(
              newSubjects,
              outcomeId,
              (outcomeId === '1' && response.follow_up_id && response.follow_up_id !== data.id)
                  ? response.follow_up_id : undefined
          );
          if (newSubjects.length) {
            setShowNoSubjects(false);
          }
        }
        else {
          const newSubj = [...subjects].filter((_v, i) => i !== subjectIndex);
          if (newSubj.length === 0) {
            history.push('/assignment/'+assignmentId+'/subjects');
          }
          else {
            updateSubjectsAndRelated(newSubj);
          }
        }
        if (returnToSubject) {
          if (returnToSubject.assignmentId !== assignmentId || returnToSubject.subjectId !== currentSubject?.id) {
            gotoHotLeadWithOutcome(returnToSubject.assignmentId, returnToSubject.subjectId, returnToSubject.outcomeId);
          }
          setReturnToSubject(null);
        }
      }
    });
  };

  const durationUnitTranslations = {
    minute: intl.formatMessage({id: 'common.time_defs.min', defaultMessage: 'minute'}),
    minutes: intl.formatMessage({id: 'common.time_defs.mins', defaultMessage: 'minutes'}),
    hour: intl.formatMessage({id: 'common.time_defs.hour', defaultMessage: 'hour'}),
    hours: intl.formatMessage({id: 'common.time_defs.hours', defaultMessage: 'hours'}),
    day: intl.formatMessage({id: 'common.time_defs.day', defaultMessage: 'day'}),
    days: intl.formatMessage({id: 'common.time_defs.days', defaultMessage: 'days'}),
    week: intl.formatMessage({id: 'common.time_defs.week', defaultMessage: 'week'}),
    weeks: intl.formatMessage({id: 'common.time_defs.weeks', defaultMessage: 'weeks'}),
    month: intl.formatMessage({id: 'common.time_defs.month', defaultMessage: 'month'}),
    months: intl.formatMessage({id: 'common.time_defs.months', defaultMessage: 'months'}),
    year: intl.formatMessage({id: 'common.time_defs.year', defaultMessage: 'year'}),
    years: intl.formatMessage({id: 'common.time_defs.years', defaultMessage: 'years'}),
  };
  const nextContactOptions = [
    {amount: 5, unit: 'minutes'},
    {amount: 10, unit: 'minutes'},
    {amount: 1, unit: 'hour'},
    {amount: 2, unit: 'hours'},
    {amount: 3, unit: 'hours'},
    {amount: 24, unit: 'hours'},
    {amount: 1, unit: 'week'},
    {amount: 2, unit: 'week'},
    {amount: 3, unit: 'week'},
    {amount: 1, unit: 'month'},
    {amount: 3, unit: 'months'},
    {amount: 6, unit: 'months'},
    {amount: 1, unit: 'year'},
  ];

  const [sendMailTemplate, setSendMailTemplate] = useState('');
  const [showSendSms, setShowSendSms] = useState(false);
  const [showNoDateInPast, setShowNoDateInPast] = useState(false);

  const [hotLeads, setHotLeads] = useState([]);
  const [hotLeadSelected, setHotLeadSelected] = useState(0);
  const [hotLeadPostPone, setHotLeadPostPone] = useState(2);

  const getSubjectContactField = (field) => {
    const assignmentSubject = getAssignmentSubject();
    if (!assignmentSubject.subject) {
      return '';
    }
    if (!assignmentSubject.subject_contact_id) {
      return assignmentSubject.subject[field];
    }
    let idx = assignmentSubject.subject.subject_contacts.findIndex(
        c => c.id === assignmentSubject.subject_contact_id);
    if (idx < 0) {
      return assignmentSubject.subject[field];
    }
    return assignmentSubject.subject.subject_contacts[idx][field];
  };
  const disableMail = () => {
    return (getSubjectContactField('email') === '' || getSubjectContactField('email') === null);
  };

  const isEmailTypePossible = (type) => {
    let allowed = '';
    switch (type) {
      case 'confirmation':
        allowed = 'meeting_invites';
        break;
      case 'direct':
        allowed = 'direct_mail';
        break;
      case 'alternative_1':
        allowed = 'alt_mail_1';
        break;
      case 'alternative_2':
        allowed = 'alt_mail_2';
        break;
      default:
        allowed = type;
        break;
    }
    return assignment['allow_sending_' + allowed]
        && assignment.email_settings
        && assignment.email_settings.subject_sender_name
        && assignment.email_settings.subject_sender_email
        && assignment.email_settings[type + '_template_id'] > 0;
  };

  const [showManuscript, setShowManuscript] = useState(false);

  const [showSearch, setShowSearch] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [search, setSearch] = useState({
    name: '',
    email: '',
    telephone: '',
    contact: '',
  });
  const getSearchFilter = () => {
    let filter = [];
    for (let p in search) {
      if (!!search[p]) {
        filter.push(p + ':"' + search[p] + '"');
      }
    }
    return filter.join(' ');
  };
  const getContactDetails = (contact) => {
    let details = [];
    if (contact.name) {
      details.push('N: ' + contact.name);
    }
    if (contact.phone) {
      details.push('T: ' + contact.phone);
    }
    if (contact.mobile) {
      details.push('M: ' + contact.mobile);
    }
    if (contact.email) {
      details.push('E: ' + contact.email);
    }
    return details.join(', ');
  };
  useEffect(() => {
    if (searchTableRef && searchTableRef.current && searchFilter) {
      searchTableRef.current.update();
    }
  }, [searchFilter]);

  const gotoHotLeadWithOutcome = (assignment_id, hotLeadId, outcomeId) => {
    // console.log('gotoHotLeadWithOutcome', assignment_id, hotLeadId, outcomeId);
    setShowSearch(false);
    if (parseInt(assignmentId) === parseInt(assignment_id)) {
      if (parseInt(activeOutcome) !== parseInt(outcomeId)) {
        history.push('/dialer/' + assignment_id + '?outcomeId=' + outcomeId + '&hotLeadId=' + hotLeadId);
        setActiveOutcome(String(outcomeId));
      }
      else {
        updateSubjectsAndRelated(subjects, outcomeId, hotLeadId);
      }
      // history.replace('/dialer/' + assignment_id);
      return false;
    }
    else {
      history.push('/dialer/' + assignment_id + '?outcomeId=' + outcomeId + '&hotLeadId=' + hotLeadId);
      return true;
    }
  };

  return (
      <Box height="100%" width="100%">
        <Dialog open={showSearch} maxWidth="xl">
          <DialogTitle style={{fontSize: '1.5rem'}}>
            <IconButton size="small" style={{float: 'right'}}
                        onClick={() => setShowSearch(false)}><Close/></IconButton>
            {(SipProvider?.call?.direction === CALL_DIRECTION_INCOMING) ?
                <Grid container><Grid item>{intl.formatMessage({
                  id: 'dialer.alert.incoming_call',
                  defaultMessage: 'You have an incoming call from {from}!',
                }, {from: SipProvider.call.counterpart?.split(' <')[0]})}</Grid>
                  <Grid item><IconButton
                      onClick={() => {
                        if (call.status !== 'accepted') setCall({...call, status: 'rejected'});
                        SipProvider.stopCall();
                      }}
                      style={{backgroundColor: themeColors.error.snackbar}}><CallEnd/></IconButton></Grid>
                  <Grid item><IconButton
                      disabled={SipProvider.call.status === CALL_STATUS_ACTIVE}
                      onClick={() => {
                        setCall({...call, status: 'accepted'});
                        SipProvider.answerCall();
                      }}
                      style={{backgroundColor: themeColors.success.snackbar}}><Call/></IconButton></Grid>
                </Grid> :
                intl.formatMessage({id: 'dialer.search.dialog_title', defaultMessage: 'Subject search'})}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1} style={{marginTop: 5}}>
              <Grid item style={{flexGrow: 1}}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                        size="small"
                        fullWidth
                        label={intl.formatMessage({
                          id: 'dialer.search.label.name',
                          defaultMessage: 'Name',
                        })}
                        value={search?.name ? search.name : ''}
                        onChange={e => setSearch({...search, name: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                        size="small"
                        fullWidth
                        label={intl.formatMessage({
                          id: 'dialer.search.label.email',
                          defaultMessage: 'Email address',
                        })}
                        value={search?.email ? search.email : ''}
                        onChange={e => setSearch({...search, email: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                        size="small"
                        fullWidth
                        label={intl.formatMessage({
                          id: 'dialer.search.label.contact',
                          defaultMessage: 'Contact',
                        })}
                        value={search?.contact ? search.contact : ''}
                        onChange={e => setSearch({...search, contact: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                        size="small"
                        fullWidth
                        label={intl.formatMessage({
                          id: 'dialer.search.label.telephone',
                          defaultMessage: 'Telephone',
                        })}
                        value={search?.telephone ? search.telephone : ''}
                        onChange={e => setSearch({...search, telephone: e.target.value})}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<Search/>}
                    style={{height: '100%'}}
                    disabled={!getSearchFilter()}
                    onClick={() => {
                      let filter = getSearchFilter();
                      if (!!filter) {
                        setSearchFilter(filter);
                      }
                    }}
                >{intl.formatMessage({id: 'common.button.search', defaultMessage: 'Search'})}</Button>
              </Grid>
              <EnhancedTable
                  ref={searchTableRef}
                  searchable={false}
                  urlState={false}
                  defaultRowsPerPage={20}
                  rowsPerPageOptions={[20]}
                  title={intl.formatMessage({
                    id: 'dialer.search.table.title',
                    defaultMessage: 'Subjects found based on search',
                  })}
                  columns={[
                    {
                      field: 'assignment.name',
                      title: intl.formatMessage({
                        id: 'dialer.search.table.column.assignment',
                        defaultMessage: 'Assignment',
                      }),
                    },
                    {
                      field: 'outcome.name',
                      title: intl.formatMessage({
                        id: 'dialer.search.table.column.outcome',
                        defaultMessage: 'Outcome',
                      }),
                    },
                    {
                      field: 'status.name',
                      title: intl.formatMessage({
                        id: 'dialer.search.table.column.status',
                        defaultMessage: 'Status',
                      }),
                    },
                    {
                      field: 'subject.name',
                      title: intl.formatMessage({
                        id: 'dialer.search.table.column.name',
                        defaultMessage: 'Subject Name',
                      }),
                    },
                    {
                      field: 'subject.email',
                      title: intl.formatMessage({
                        id: 'dialer.search.table.column.email',
                        defaultMessage: 'Subject Email',
                      }),
                    },
                    {
                      field: 'subject.main_phone',
                      title: intl.formatMessage({
                        id: 'dialer.search.table.column.telephone',
                        defaultMessage: 'Subject Phone',
                      }),
                    },
                    {
                      field: 'subject_contacts',
                      title: intl.formatMessage({
                        id: 'dialer.search.table.column.contacts',
                        defaultMessage: 'Subject Contacts',
                      }),
                      render: row => row.subject?.subject_contacts?.map((c, i) =>
                          <Pill color={themeColors.gray['300']}
                                key={'subject_' + row.subject.id + '_contact_' + i}
                                label={getContactDetails(c)}/>),
                    },
                  ]}
                  query={'assignmentSubjectSearch'}
                  filter={searchFilter}
                  fields={'id outcome{id name} status{name} subject{name main_phone subject_contacts{name email phone mobile}} assignment{id name}'}
                  actions={[
                    {
                      icon: OpenInNew,
                      tooltip: intl.formatMessage({
                        id: 'dialer.search.table.action.goto_subject',
                        defaultMessage: 'Goto assignment and subject',
                      }),
                      rowClick: true,
                      onClick: row => {
                        let s = getAssignmentSubject();
                        if (SipProvider?.call?.direction === CALL_DIRECTION_INCOMING) {
                          setReturnToSubject({
                            assignmentId: assignmentId,
                            subjectId: s.id,
                            outcomeId: activeOutcome,
                          });
                        }
                        saveSubject(s).then(_ => gotoHotLeadWithOutcome(row.assignment.id, row.id, row.outcome.id));
                      },
                    },
                  ]}
              />
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={showContacts} fullWidth maxWidth={false}>
          <DialogTitle>
            <IconButton size="small" style={{float: 'right'}}
                        onClick={() => setShowContacts(false)}><Close/></IconButton>
            {intl.formatMessage({
              id: 'dialer.contact.search.dialog_title',
              defaultMessage: 'Contact search',
            })}
          </DialogTitle>
          <DialogContent>
              <ContactsFilter subject={getAssignmentSubject()?.subject} onCopySubjectContact={onCopySubjectContact}/>
          </DialogContent>
        </Dialog>
        <Snackbar
            open={showAssignmentHasBeenClosed}
            anchorOrigin={{horizontal: 'center', vertical: 'top'}}
            onClose={() => {
              setShowAssignmentHasBeenClosed(false);
              window.location.href = '/dialer';
            }}>
          <SnackbarContent
              message={intl.formatMessage({
                id: 'dialer.alert.max-closed-subjects-reached',
                defaultMessage: 'The maximum number of closed subjects has been reached and the assignment has been closed. Please choose another assignment.',
              })}
              style={{backgroundColor: themeColors.secondary.light}}/>
        </Snackbar>
        <Snackbar
            open={alertMessage.length > 0}
            anchorOrigin={{horizontal: 'center', vertical: 'top'}}
            onClose={() => setAlertMessage('')}>
          <SnackbarContent message={alertMessage} style={{backgroundColor: themeColors.error.light}}/>
        </Snackbar>
        {hotLeads.length > 0 && subjects[subjectIndex] && hotLeads[hotLeadSelected].id !== subjects[subjectIndex].id &&
            <HotLeads
                assignmentId={assignmentId}
                hotLeads={hotLeads}
                setHotLeads={setHotLeads}
                hotLeadSelected={hotLeadSelected}
                setHotLeadSelected={setHotLeadSelected}
                nextContactOptions={nextContactOptions}
                durationUnitTranslations={durationUnitTranslations}
                hotLeadPostPone={hotLeadPostPone}
                setHotLeadPostPone={setHotLeadPostPone}
                client={client}
                gotoHotLeadWithOutcome={gotoHotLeadWithOutcome}
            />}
        <Backdrop style={{zIndex: 1200}} open={isLoadingAssignment || isLoadingSubjects}><CircularProgress
            color="secondary"/></Backdrop>
        {!assignmentId ?
            <Grid container justifyContent="center" alignItems="center" style={{height: 'calc(100vh - 175px)'}}>
              <Grid item>{intl.formatMessage({
                id: 'dialer.alert.no_assignment_selected',
                defaultMessage: 'Please select an assignment in the selector above!',
              })}</Grid>
            </Grid> :
            <Grid
                container
                spacing={3}
                style={{
                  height: 'calc(100vh - 135px)',
                }}
            >
              <Snackbar
                  open={showNoSubjects}
                  anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                  message={intl.formatMessage({
                    id: 'dialer.alert.no_subjects_left',
                    defaultMessage: 'There are no more subjects on this assignment, please select another assignment.',
                  })}
                  style={{top: 60}}
              />
              {showSubjectList ? <Grid
                      style={{height: '100%', display: 'flex'}}
                      item
                      xs={10}
                  >
                    <Card elevation={4} style={{width: '100%', height: '100%'}}>
                      <DataGridPro
                          apiRef={dataGridRef}
                          sx={{
                            '& .row-marked': {
                              backgroundColor: lighten(themeColors.primary.main, 0.8),
                              '&.Mui-hovered': {
                                backgroundColor: lighten(themeColors.primary.main, 0.7),
                              },
                            },
                          }}
                          columns={columns}
                          rows={subjects.map(s => {
                            const subject = {
                              id: s.id,
                              subject_id: s.subject.id,
                              name: s.subject.name,
                              address_1: s.subject.address_1,
                              zip: s.subject.zip,
                              city: s.subject.city,
                              next_contact_at: s.next_contact_at,
                              business_type: s.subject.business_type,
                              employees: s.subject.employees,
                              calls_made: s.calls_made,
                              subject_contacts_count: s.subject.subject_contacts.length,
                            };
                            s.subject_field_data?.forEach(d => {
                              subject['subject_field_data_' + d.subject_field_id] = d.data;
                            });
                            return subject;
                          })}
                          density="compact"
                          getRowClassName={({row}) => (subjects[subjectIndex] && row.id === subjects[subjectIndex].id)
                              ? 'row-marked'
                              : ''}
                          sortModel={sortModel}
                          onSortModelChange={newSortModel => {
                            if (newSortModel.findIndex(s => s.field === 'next_contact_at') === -1) {
                              newSortModel.push({field: 'next_contact_at', sort: 'asc'});
                            }
                            setSortModel(newSortModel);
                          }}
                          rowSelectionModel={[]}
                          onRowSelectionModelChange={sel => {
                            let index = subjects.findIndex(s => s.id === sel[0]);
                            setSubjectIndex(index);
                            setSelectedPhone(subjects[index]?.subject?.main_phone?.replaceAll(' ', ''));
                            setSortedIndex(dataGridRef.current?.getSortedRowIds().findIndex(r => r === sel[0]));
                          }}
                          disableMultipleSelection
                          disableVirtualization
                      />
                    </Card>
                  </Grid> :
                  <Grid
                      style={{display: !showSubjectList ? '' : 'none', height: '100%'}}
                      item
                      xs={10}
                  >
                    <Grid
                        container
                        spacing={1}>
                      <Grid item xs={3}>
                        <PhoneSelector
                            assignmentSubject={getAssignmentSubject()}
                            onCallState={SipProvider && SipProvider.call?.status !== CALL_STATUS_IDLE}
                            telSelect={{viewTog: selectedPhone, setViewTog: setSelectedPhone}}
                            selectedCountryState={{selectedCountry, setSelectedCountry}}
                            countries={countries}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Card
                            elevation={4}
                            style={{
                              height: '100%',
                              // border: "2px solid hotpink",
                            }}
                        >
                          <PhoneDialer
                              sendDTMF={SipProvider?.sendDTMF}
                              callSession={SipProvider?.call}
                              sipStatus={SipProvider?.sip?.status}
                              handleCalling={handleCalling}
                              disableDialing={SipProvider?.sip?.status === SIP_STATUS_DISCONNECTED
                                  || SipProvider?.call?.status !== CALL_STATUS_IDLE
                                  || !selectedPhone}
                          />
                        </Card>
                      </Grid>
                      <Grid item xs={3}>
                        <Card
                            elevation={4}
                            style={{
                              height: 229,
                            }}
                        >
                          <Logs callLog={getAssignmentSubject('call_log')}
                                statusLog={getAssignmentSubject('status_log')}></Logs>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card
                            elevation={4}
                            style={{
                              height: '100%',
                            }}
                        >
                          <CardContent style={{padding: '0.8rem'}}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                {sendMailTemplate !== '' && <SendMail
                                    template={sendMailTemplate}
                                    getSubjectContactField={getSubjectContactField}
                                    onClose={(_e, status) => {
                                      if (status === 'failed') {
                                        setAlertMessage(
                                            intl.formatMessage({
                                              id: 'dialer.card.actions.send_mail.failed',
                                              defaultMessage: 'The message was not send. Please check the email addresses and contents!',
                                            }));
                                      }
                                      else {
                                        setSendMailTemplate('');
                                        updateSubject({
                                          status_history: [
                                            {
                                              id: 'new',
                                              status: {name: 'Fremsendt'},
                                              user: {name: authUser().name},
                                              created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                                            },
                                            ...getAssignmentSubject().status_history,
                                          ],
                                        });
                                      }
                                    }}
                                    assignmentSubject={getAssignmentSubject()}
                                    assignmentId={assignmentId}
                                    history={history}
                                    open={sendMailTemplate !== ''}
                                />}
                                <Typography variant="h3">{intl.formatMessage({
                                  id: 'dialer.card.actions.send_message',
                                  defaultMessage: 'Send message',
                                })}</Typography>
                                <ButtonGroup fullWidth size="small" variant="contained"
                                             color="primary">
                                  {isEmailTypePossible('confirmation') &&
                                      <Button
                                          disabled={disableMail()}
                                          onClick={() => saveSubject(getAssignmentSubject())
                                              .then(() => setSendMailTemplate('confirmation'))}
                                      >{'Bekræft'}</Button>}
                                  {isEmailTypePossible('direct') &&
                                      <Button
                                          disabled={disableMail()}
                                          onClick={() => saveSubject(getAssignmentSubject()).then(() => setSendMailTemplate('direct'))}
                                      >{'Direkte'}</Button>}
                                  {isEmailTypePossible('alternative_1') &&
                                      <Button
                                          disabled={disableMail()}
                                          onClick={() => saveSubject(getAssignmentSubject())
                                              .then(() => setSendMailTemplate('alternative_1'))}
                                      >{'Alt. 1'}</Button>}
                                  {isEmailTypePossible('alternative_2') &&
                                      <Button
                                          disabled={disableMail()}
                                          onClick={() => saveSubject(getAssignmentSubject())
                                              .then(() => setSendMailTemplate('alternative_2'))}
                                      >{'Alt. 2'}</Button>}
                                  {assignment.allow_sending_sms &&
                                      <Button
                                          disabled={!getSubjectContactField('mobile')}
                                          onClick={() => saveSubject(getAssignmentSubject()).then(() => setShowSendSms(true))}
                                      >{'SMS'}</Button>}
                                </ButtonGroup>
                                {showSendSms && <SendSms
                                    open={showSendSms}
                                    getSubjectContactField={getSubjectContactField}
                                    onClose={(messageStatus) => {
                                      setShowSendSms(false);
                                      // console.log(messageStatus);
                                      if (messageStatus.hasOwnProperty('status') && !messageStatus.status) {
                                        setAlertMessage(intl.formatMessage({
                                          id: 'dialer.card.actions.send_message.sms_error',
                                          defaultMessage: 'An error occurred while trying to deliver the message',
                                        }));
                                      }
                                    }}
                                    history={history}
                                    assignmentId={assignmentId}
                                    assignmentSubject={getAssignmentSubject()}
                                />}
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="h3">
                                  {intl.formatMessage(
                                      {
                                        id: 'dialer.card.actions.next_contact_at.heading',
                                        defaultMessage: 'Next contact',
                                      })}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={1}>
                                  <Grid item xs={8}>
                                    <DatePicker
                                        selected={getAssignmentSubject().next_contact_at ? moment(getAssignmentSubject().next_contact_at).toDate() : ''}
                                        onChange={date => {
                                          setShowNoDateInPast(false);
                                          let datetime = moment(date);
                                          if (datetime.isBefore()) {
                                            setShowNoDateInPast(true);
                                          }
                                          updateSubject({next_contact_at: date});
                                        }}
                                        locale="da"
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        showWeekNumbers
                                        dateFormat='dd-MM-yyyy HH:mm'
                                        minDate={!authUser().hasRole('manager') ? new Date() : new Date("01-01-1900")}
                                        calendarStartDay={1}
                                        filterDate={(date) => {
                                          const day = moment(date).day();
                                          return day !== 0 && day !== 6;
                                        }}
                                        // minTime={new Date(0, 0, 0, 6, 0)}
                                        // maxTime={new Date(0, 0, 0, 20, 0)}
                                        placeholderText= {intl.formatMessage({id: 'dialer.card.actions.next_contact_at.label', defaultMessage: 'Date and time',})}
                                    />
                                    {showNoDateInPast && !authUser().hasRole('manager') && <label style={{color: "red"}} >{intl.formatMessage({id: 'dialer.card.actions.next_contact_at.help', defaultMessage: 'You are not allowed to set a date in the past!',})}</label>}
                                    {/*<TextField
                                        classes={{root: classes.textField}}
                                        error={showNoDateInPast}
                                        fullWidth
                                        size="small"
                                        id="next_contact_at"
                                        label={intl.formatMessage(
                                            {
                                              id: 'dialer.card.actions.next_contact_at.label',
                                              defaultMessage: 'Date and time',
                                            })}
                                        type="datetime-local"
                                        value={moment(getAssignmentSubject().next_contact_at).isValid() ?
                                            moment(getAssignmentSubject().next_contact_at).format('YYYY-MM-DDTHH:mm') :
                                            ''}
                                        onChange={e => {
                                          setShowNoDateInPast(false);
                                          let datetime = moment(e.target.value);
                                          if (datetime.isBefore()) {
                                            setShowNoDateInPast(true);
                                            datetime = moment();
                                          }
                                          updateSubject({next_contact_at: datetime.format('YYYY-MM-DD HH:mm:00')});
                                        }}
                                        helperText={
                                          showNoDateInPast
                                              ? intl.formatMessage(
                                                  {
                                                    id: 'dialer.card.actions.next_contact_at.help',
                                                    defaultMessage: 'You are not allowed to set a date in the past!',
                                                  })
                                              : ''}
                                        inputProps={{
                                          min: moment().format('YYYY-MM-DDTHH:mm'),
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                    />*/}
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Button
                                        size="small"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={() => calendarWindowAssignment(true)}
                                    >{intl.formatMessage(
                                        {
                                          id: 'dialer.card.actions.button.calendar',
                                          defaultMessage: 'Calendar',
                                        })}</Button>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <TextField
                                        classes={{root: classes.textField}}
                                        select
                                        fullWidth
                                        size="small"
                                        label={intl.formatMessage(
                                            {
                                              id: 'dialer.card.actions.next_contact.label',
                                              defaultMessage: 'Choose duration',
                                            })}
                                        value={getAssignmentSubject().next_contact_duration}
                                        onChange={e => {
                                          let d = e.target.value.split(',');
                                          updateSubject({
                                            next_contact_duration: e.target.value,
                                            next_contact_at: e.target.value ? moment().add(d[0], d[1]) : '',
                                          });
                                        }}
                                    >
                                      {nextContactOptions.map((d, k) =>
                                          <MenuItem dense key={'next_contact_duration_' + k}
                                                    value={d.amount + ',' + d.unit}>
                                            {intl.formatMessage(
                                                {
                                                  id: 'dialer.card.actions.next_contact.duration',
                                                  defaultMessage: 'In {amount} {unit}',
                                                },
                                                {
                                                  amount: d.amount,
                                                  unit: durationUnitTranslations[d.unit],
                                                },
                                            )}
                                          </MenuItem>,
                                      )}
                                      <MenuItem dense value={''}>{intl.formatMessage({
                                        id: 'dialer.card.actions.next_contact.clear',
                                        defaultMessage: 'Clear date',
                                      })}</MenuItem>
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Button
                                        size="small"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={() => updateSubject(
                                            {next_contact_at: moment().set('hour', 8).set('minutes', 0).add(1, 'day')})}
                                    >
                                      {intl.formatMessage(
                                          {
                                            id: 'dialer.card.actions.button.tomorrow',
                                            defaultMessage: 'Tomorrow',
                                          })}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={7}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}><Subject
                              assignmentSubject={subjects[subjectIndex] ? subjects[subjectIndex] : {}}
                              setSubject={updateSubject}
                              subjectFields={subjectFields}/></Grid>
                          <Grid item xs={12}><SubjectContacts
                              subjectContact={getAssignmentSubject('contact')}
                              updateSubjectContact={updateSubjectContact}
                              selectSubjectContact={id => updateSubject({
                                ...getAssignmentSubject(),
                                subject_contact_id: id,
                              })}
                              contacts={getAssignmentSubject('subject_contacts')}
                              departments={departments}
                              isCreatingNewContact={isCreatingNewContact}
                          /></Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={5}>
                        <Comments
                            prependNewComments={assignment.add_phoner_comment_details}
                            assignmentSubject={getAssignmentSubject()}
                            updateAssignmentSubject={(property, value) => updateSubject({[property]: value})}
                            questions={assignment.questions.filter(q => q.text && q.text.length)}
                            setAnswer={(questionId, answer) => {
                              if (subjectIndex === null) {
                                return;
                              }
                              const sub = {...subjects[subjectIndex]};
                              if (!sub.answers) {
                                sub.answers = [];
                              }
                              let i = sub.answers.findIndex(a => a.assignment_question_id === questionId);
                              if (i < 0) {
                                sub.answers.push({text: answer, assignment_question_id: questionId});
                              }
                              else {
                                sub.answers[i].text = answer;
                              }
                              updateSubject(sub);
                            }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>}
              <Grid item xs={2} container spacing={2}
                    style={{justifyContent: 'space-between', gap: 8, marginTop: 0}}>
                <Button
                    onClick={() => {
                      if (showSubjectList) {
                        setSortedIds(dataGridRef.current.getSortedRowIds());
                      }
                      setShowSubjectList(!showSubjectList);
                    }}
                    size="small"
                    variant="outlined"
                    startIcon={showSubjectList ? <ContactPhone/> : <ListAltOutlined/>}
                >{showSubjectList ? 'Behandling' : 'Emne liste'}</Button>
                <Grid>
                  <IconButton
                      onClick={() => setShowSearch(true)}
                      size="small"
                      title={intl.formatMessage({id: 'dialer.button.search', defaultMessage: 'Search'})}
                  ><Search/></IconButton>
                  <IconButton
                      onClick={() => setShowContacts(true)}
                      size="small"
                      title={intl.formatMessage({id: 'dialer.button.contacts.search', defaultMessage: 'Contacts'})}
                  ><Contacts/></IconButton>
                  <IconButton
                      onClick={() => setShowManuscript(true)}
                      size="small"
                      title={intl.formatMessage({
                        id: 'dialer.button.manuscript',
                        defaultMessage: 'Manuscript',
                      })}
                  ><Info/></IconButton>
                </Grid>
                <Outcomes
                    onCallState={SipProvider && !!SipProvider.call && SipProvider.call?.status !== CALL_STATUS_IDLE}
                    outcomes={outcomes}
                    activeOutcome={activeOutcome}
                    setActiveOutcome={setActiveOutcome}
                    current={subjectIndex !== null ? subjectIndex + 1 : 0}
                    currentSubject={subjects[subjectIndex]}
                    total={subjects.length}
                    setOutcome={handleOutcomeChange}
                />
              </Grid>
              <Dialog open={showManuscript} onClose={() => setShowManuscript(false)}>
                <DialogTitle variant="h2">
                  <IconButton size="small" style={{float: 'right'}}
                              onClick={() => setShowManuscript(false)}><Close/></IconButton>
                  {intl.formatMessage({
                    id: 'dialer.heading.manuscript',
                    defaultMessage: 'Assignment Manuscript',
                  })}
                </DialogTitle>
                <DialogContent
                    dangerouslySetInnerHTML={{__html: assignment.email_settings?.phoner_information ? assignment.email_settings.phoner_information.replaceAll('«assignment.name»', assignment.name) : ''}}/>
              </Dialog>
            </Grid>}
      </Box>
  );
};

Dialer.contextTypes = SipProvider.childContextTypes;

export default Dialer;
