import {useIntl} from "react-intl";
import {graphQLApi} from "../../../services/GraphQLApi";
import {authHeader, getApiDomain, useAuthDispatch} from "../../../contexts/Auth";
import {useEffect, useState} from "react";
import moment from "moment";
import {
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@material-ui/core";
import {formatSeconds} from "../../../contexts/Calls";
import {useHistory} from "react-router-dom";

export default function CallStatistics() {
    const intl = useIntl();
    const history = useHistory();
    const client = new graphQLApi(useAuthDispatch(), history);
    const [isLoading, setIsLoading] = useState(false);
    const [stats, setStats] = useState([]);
    const [from, setFrom] = useState(moment().format('YYYY-MM-DD'));
    const [to, setTo] = useState(moment().format('YYYY-MM-DD'));

    useEffect(() => {
        setIsLoading(true);
        client.query('{' +
            'callStatistics(from:"' + from + '", to:"' + to + '"){user_id name seconds calls}' +
            '}').then(r => {
            setIsLoading(false);
            if (r?.hasOwnProperty('callStatistics')) {
                setStats(r.callStatistics);
            }
        });
    }, [to, from]);

    return <Paper>
        <Card>
            <CardHeader
                title={intl.formatMessage({
                    id: "call-statistics.title",
                    defaultMessage: "Phoner Call Statistics"
                })}
                subheader={<Grid container spacing={2} alignItems="center">
                    <Grid item><TextField
                        label={"From"}
                        type="date"
                        value={from}
                        onChange={e => setFrom(e.target.value)}
                    /></Grid>
                    <Grid item><TextField
                        label={"To"}
                        type="date"
                        value={to}
                        onChange={e => setTo(e.target.value)}
                    /></Grid>
                    <Grid item>
                        <Button
                            href={getApiDomain() + '/call-statistics?from=' + from + '&to=' + to + '&token=' + authHeader(true)}>{
                            intl.formatMessage({id: "common.button.download", defaultMessage: "Download"})
                        }</Button>
                    </Grid>
                    {isLoading && <Grid item><CircularProgress/></Grid>}
                </Grid>}
            />
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{intl.formatMessage({
                                id: "call-statistics.column.user",
                                defaultMessage: "User"
                            })}</TableCell>
                            <TableCell align="right">{intl.formatMessage({
                                id: "call-statistics.column.calls",
                                defaultMessage: "Calls"
                            })}</TableCell>
                            <TableCell align="right">{intl.formatMessage({
                                id: "call-statistics.column.duration",
                                defaultMessage: "Duration"
                            })}</TableCell>
                            <TableCell align="right">{intl.formatMessage({
                                id: "call-statistics.column.duration_avarage",
                                defaultMessage: "Avarage per call"
                            })}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{stats.map((stat, idx) => <TableRow key={'call-statistics-row-' + idx}>
                        <TableCell>{stat.name}</TableCell>
                        <TableCell align="right">{stat.calls}</TableCell>
                        <TableCell align="right">{formatSeconds(stat.seconds)}</TableCell>
                        <TableCell align="right">{formatSeconds(stat.seconds / stat.calls)}</TableCell>
                    </TableRow>)}</TableBody>
                </Table>
            </TableContainer>
        </Card>
    </Paper>
}