import React, {useEffect} from 'react';
import moment from 'moment';
import 'moment/locale/da.js';
import 'moment/locale/en-gb.js';
import {authUser, useAuthDispatch} from "./Auth";
import {graphQLApi} from "../services/GraphQLApi";

const CallsContext = React.createContext();


export const formatSeconds = (secs) => {
    let hours = Math.floor(secs / 60 / 60);
    return (hours
            ? hours.toFixed(0) + ':' + Math.floor((secs - (hours * 60 * 60)) / 60).toFixed(0).padStart(2, '0')
            : Math.floor(secs / 60))
        + ':' + Number(secs % 60).toFixed(0).padStart(2, '0');
}

export const CallsProvider = (props) => {
    const client = new graphQLApi(useAuthDispatch());
    const [callStats, setCallStats] = React.useState({
        calls: '0',
        duration: '0:00',
        durationSecs: 0,
    });
    const updateCallStats = () => {
        if (authUser()?.id) {
            client.query('{assignmentCalls(filter:{user_id:' + authUser().id + ', created_at_date:"' + moment().format('YYYY-MM-DD') + '"}){total data{duration}}}').then(r => {
                if (r?.assignmentCalls) {
                    let dur = 0;
                    r.assignmentCalls.data.forEach(c => dur += c.duration);
                    let d = {
                        calls: r.assignmentCalls.total,
                        durationSecs: dur,
                        duration: formatSeconds(dur),
                    }
                    setCallStats(d);
                }
            })
        }
    }
    useEffect(() => {
        updateCallStats();
    }, []);

    const addSeconds = (secs) => {
        let dur = callStats.durationSecs + secs;
        setCallStats({...callStats, durationSecs: dur, duration: formatSeconds(dur)});
    }

    return <CallsContext.Provider value={{callStats, updateCallStats, addSeconds}}>
        {props.children}
    </CallsContext.Provider>;
};

export function useCalls() {
    const context = React.useContext(CallsContext)
    if (context === undefined) {
        throw new Error('useCalls must be used within a CallsProvider')
    }
    return context
}